import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { StoreConfig } from 'models/stores'
import { TimeCode } from 'types/Product'
import { ProgramStep } from 'types/ReplayProgram'
// import { persistStore } from 'redux-persist'

export const appInitialState = {
	storeConfig: {
		domain: null,
		path: null,
		userAgent: null,
		consent: {
			technical: false,
			analytics: false,
			tos: false,
			expiration: 365
		},
		viewerId: null,
		productTimecode: null
	},
	storeDatas: null,
	storeCurrentStep: null,
	storeLivestreamSeekTo: null,
	storeProducts: [],
	storeStepsProgram: [],
	storeTimecodes: [],
	storePopin: null,
	storeModal: null,
	storeIsFullscreen: false,
	storeIsOffline: false,
	storeResetTc: false,
	storeCounter: null,
	storeCommercialOptin: null
}

export type State = {
	storeConfig: StoreConfig
	storeDatas: any
	storeCurrentStep?: ProgramStep
	storeLivestreamSeekTo: any
	storeProducts: any
	storeStepsProgram: ProgramStep[]
	storeTimecodes: TimeCode[]
	storePopin: any
	storeModal: any
	storeIsFullscreen: boolean
	storeIsOffline: boolean
	storeResetTc: boolean
	storeCounter: number
	storeCommercialOptin: Record<string, any>
}

export const actionTypes = {
	SET_CONFIG: 'SET_CONFIG',
	SET_DATAS: 'SET_DATAS',
	SET_CURRENT_STEP: 'SET_CURRENT_STEP',
	SET_PRODUCTS: 'SET_PRODUCTS',
	SET_STEPS_PROGRAM: 'SET_STEPS_PROGRAM',
	LIVESTREAM_SEEK_TO: 'LIVESTREAM_SEEK_TO',
	SET_MESSAGES: 'SET_MESSAGES',
	SET_TIMECODES: 'SET_TIMECODES',
	SET_REPLAY_TIMECODES: 'SET_REPLAY_TIMECODES',
	ADD_POPIN: 'ADD_POPIN',
	REMOVE_POPIN: 'REMOVE_POPIN',
	ADD_MODAL: 'ADD_MODAL',
	REMOVE_MODAL: 'REMOVE_MODAL',
	SET_PIP: 'SET_PIP',
	UNSET_PIP: 'UNSET_PIP',
	SET_FULLSCREEN: 'SET_FULLSCREEN',
	UNSET_FULLSCREEN: 'UNSET_FULLSCREEN',
	SET_IS_OFFLINE: 'SET_IS_OFFLINE',
	UNSET_IS_OFFLINE: 'UNSET_IS_OFFLINE',
	SET_COUNTER: 'SET_COUNTER',
	SET_CONSENT: 'SET_CONSENT'
}

// REDUCER

export const reducer: (state: State, action: any) => any = function (state: any, action: any): any {
	switch (action.type) {
		//
		case actionTypes.SET_CONFIG:
			return Object.assign({}, state, {
				storeConfig: action.config
			})

		case actionTypes.SET_DATAS:
			return Object.assign({}, state, {
				storeDatas: action.datas
			})

		case actionTypes.SET_CURRENT_STEP:
			return Object.assign({}, state, {
				storeCurrentStep: action.step as ProgramStep
			})
		case actionTypes.LIVESTREAM_SEEK_TO:
			if (action.time === state.storeLivestreamSeekTo) {
				return Object.assign({}, state, {
					storeLivestreamSeekTo: action.time,
					storeResetTc: !state.storeResetTc
				})
			} else {
				return Object.assign({}, state, {
					storeLivestreamSeekTo: action.time
				})
			}

		case actionTypes.SET_PRODUCTS:
			return Object.assign({}, state, {
				storeProducts: action.products
			})

		case actionTypes.SET_STEPS_PROGRAM:
			return Object.assign({}, state, {
				storeStepsProgram: action.stepsProgram as ProgramStep[]
			})

		case actionTypes.SET_TIMECODES:
			return Object.assign({}, state, {
				storeTimecodes: action.timecodes
			})

		case actionTypes.ADD_POPIN:
			return Object.assign({}, state, {
				storePopin: action.popin
			})

		case actionTypes.REMOVE_POPIN:
			return Object.assign({}, state, {
				storePopin: null
			})

		case actionTypes.ADD_MODAL:
			return Object.assign({}, state, {
				storeModal: action.modal
			})

		case actionTypes.REMOVE_MODAL:
			return Object.assign({}, state, {
				storeModal: null
			})

		// FULLSCREEN

		case actionTypes.SET_FULLSCREEN:
			return Object.assign({}, state, {
				storeIsFullscreen: true
			})

		case actionTypes.UNSET_FULLSCREEN:
			return Object.assign({}, state, {
				storeIsFullscreen: false
			})

		case actionTypes.SET_IS_OFFLINE:
			return Object.assign({}, state, {
				storeIsOffline: true
			})

		case actionTypes.UNSET_IS_OFFLINE:
			return Object.assign({}, state, {
				storeIsOffline: false
			})

		case actionTypes.SET_COUNTER:
			return Object.assign({}, state, {
				storeCounter: action.counter
			})

		case actionTypes.SET_CONSENT: {
			return Object.assign({}, state, {
				storeConfig: {
					...state.storeConfig,
					consent: { ...action }
				}
			})
		}

		default:
			return state
	}
}

export function initializeStore(initialState = appInitialState) {
	return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}
