import Config from '@styles/default/config/index'
import Merge from 'deepmerge'
import PlusJakartaSansBold2 from '@assets/fonts/PlusJakartaSans-Bold.woff2'
import PlusJakartaSansBold from '@assets/fonts/PlusJakartaSans-Bold.woff'
import PlusJakartaSansRegular from '@assets/fonts/PlusJakartaSans-Regular.woff'
import PlusJakartaSansRegular2 from '@assets/fonts/PlusJakartaSans-Regular.woff2'
import { css } from '@emotion/react'

const fadeAndScaleOnClick = (scale: boolean = true) => css`
	transition:
		opacity 400ms ${Config.eases.outExpo} 0ms,
		transform 400ms ${Config.eases.outExpo} 0ms;
	&:active {
		opacity: 0.5;
		transform: ${scale ? 'scale(0.95)' : 'scale(1)'};
	}
	&:hover {
		opacity: 0.5;
	}
`

const Theme = {
	fontFamilies: {
		Second: 'Second',
		Main: 'Main'
	},

	fonts: [
		{
			name: 'PlusJakartaSans',
			weight: Config.fontWeights.regular,
			style: Config.fontStyles.normal,
			woff: PlusJakartaSansRegular,
			woff2: PlusJakartaSansRegular2
		},
		{
			name: 'PlusJakartaSans',
			weight: Config.fontWeights.bold,
			style: Config.fontStyles.normal,
			woff: PlusJakartaSansBold,
			woff2: PlusJakartaSansBold2
		}
	],
	text: {
		thin: {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 400,
			color: '#9F9F9F'
		},
		regular: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 400,
			color: 'white'
		},
		bold: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 700,
			color: 'white'
		},
		boldTitle: {
			fontSize: '20px',
			lineHeight: '24px',
			fontWeight: 700,
			color: 'black'
		}
	},
	colors: {
		text_main: `#000000`,
		text_second: `#333333`,
		background: `#FEF8ED`,
		cta_main: `#FEF8ED`,
		cta_second: `#666666`,
		success_green: `#00D5A3`,
		grey1: '#9E9E9E',
		green: (a = 1) => {
			return `rgba(0, 213, 163, ${a})`
		},
		red: (a = 1) => {
			return `rgba(213, 0, 50, ${a})`
		},
		pink: (a = 1) => {
			return `rgba(255, 203, 215, ${a})`
		},
		orange: (a = 1) => {
			return `rgba(255, 165, 0, ${a})`
		},
		withOpacity: (color: string, opacity: number) => {
			if (color.length < 8 && opacity < 100) {
				return `${color}${opacity}`
			}
			return color
		}
	},

	// breakPoints: ['950px', '1220px', '1441px'],

	breakPoints: ['40em', '52em', '64em', '100em'],

	grid: {
		width: [0.85, 0.9],
		maxWidth: [1044]
	},

	zIndices: {
		stories: 1,
		timeline: 2,
		livestream: 1,
		header: 3,
		bottomBar: 2,
		liveProducts: 4,
		liveChat: 3,
		modal: 100,
		panels: 200,
		popin: 210,
		pip: 900,
		unmute: 900,
		pause: 2,
		play: 2
	},
	preLive: {
		separationValues: [80, 100]
	},
	squaredEdges: null,
	spacing: (multiple) => `${multiple * 5}px`,
	multiSpacing: (topBottom, leftRight) =>
		`${topBottom * 5}px ${leftRight * 5}px ${topBottom * 5}px ${leftRight * 5}px`,
	fadeAndScaleOnClick
}

const FinalConfig = Merge(Config, Theme)

// Parse breakpoints to integer

for (const b of FinalConfig.breakPoints) {
	FinalConfig.breakPointsInt.push(parseInt(b))
	// parse Breakpoints to medias
}

// Create css breakpoints medias

FinalConfig.media = (bp) => {
	return `screen and (min-width: ${FinalConfig.breakPoints[bp]})`
}

FinalConfig.mediaMin = (bp) => {
	return `screen and (min-width: ${FinalConfig.breakPoints[bp]})`
}

FinalConfig.mediaMax = (bp) => {
	return `screen and (max-width: ${FinalConfig.breakPoints[bp]})`
}

export default FinalConfig
