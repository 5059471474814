export default {
	ultralight: 200,
	light: 300,
	book: 350,
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
	black: 800
}
