import Firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import 'firebase/compat/analytics'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

const NEXT_PUBLIC_DB_ENV = publicRuntimeConfig.NEXT_PUBLIC_DB_ENV

let config = {}

if (NEXT_PUBLIC_DB_ENV === 'development') {
	config = {
		apiKey: 'AIzaSyBNfjl3FTFBKCmQSZygNA_9hlCJW5ygM1s',
		authDomain: 'aploze-app-dev.firebaseapp.com',
		projectId: 'app-dev-61655',
		storageBucket: 'aploze-app-dev-61655.appspot.com',
		messagingSenderId: '693450848684',
		appId: '1:693450848684:web:0267124eb68b74b0a4a209',
		measurementId: 'G-JW3B702YDY'
	}
}

if (NEXT_PUBLIC_DB_ENV === 'staging') {
	config = {
		apiKey: 'AIzaSyDttJi4dm9zaDm3z_d6bKVKYCf7zi9u1_4',
		authDomain: 'aploze-app-staging.firebaseapp.com',
		projectId: 'aploze-app-staging',
		storageBucket: 'aploze-app-staging.appspot.com',
		messagingSenderId: '693450848684',
		appId: '1:693450848684:web:0267124eb68b74b0a4a209',
		measurementId: 'G-JW3B702YDY'
	}
}

if (NEXT_PUBLIC_DB_ENV === 'prod') {
	config = {
		apiKey: 'AIzaSyDggqZS7ld8XPODzWTOS4ylQLr2EO1X-9o',
		authDomain: 'aploze-app-prod.firebaseapp.com',
		projectId: 'aploze-app-prod',
		storageBucket: 'aploze-app-prod.appspot.com',
		messagingSenderId: '251818275335',
		appId: '1:251818275335:web:bf3af41d338c72f31670a2',
		measurementId: 'G-19R3HEMQ0F'
	}
}

if (!Firebase.apps.length) {
	Firebase.initializeApp(config)
	// Firebase.analytics()
}

export default Firebase
