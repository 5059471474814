export default {
	black: (a = 1) => {
		return `rgba(0, 0, 0, ${a})`
	},
	white: (a = 1) => {
		return `rgba(255, 255, 255, ${a})`
	},
	grey: (amount = 0.5, alpha = 1) => {
		return `rgba(${Math.round((1 - amount) * 255)}, ${Math.round((1 - amount) * 255)}, ${Math.round(
			(1 - amount) * 255
		)}, ${alpha})`
	},
	grey2: '#D9D9D9',
	brown: '#C8AE6A',
	yellow: '#ECCD7D'
}
