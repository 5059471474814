import eases from './eases'
import colors from './colors'
import zIndices from './zIndices'
import fontWeights from './fontWeights'
import fontStyles from './fontStyles'
import fontFamilies from './fontFamilies'

export default {
	fonts: [],
	breakPointsInt: [],
	media: null,
	mediaMin: null,
	mediaMax: null,
	colors,
	eases,
	zIndices,
	fontWeights,
	fontStyles,
	fontFamilies
}
