import { ThemeProvider } from '@emotion/react'
import { DbProvider } from '@helpers/contexts/dbContext'
import withReduxStore, { Store } from '@helpers/wrappers/withReduxStore'
import Theme from '@styles/theme'
import App, { AppProps } from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import 'video.js/dist/video-js.css'

interface Props {
	reduxStore: Store
}

class Aploze extends App<Props & AppProps> {
	render() {
		const { Component, pageProps, reduxStore } = this.props

		return (
			<>
				<DbProvider>
					<Provider store={reduxStore}>
						<ThemeProvider theme={{ breakpoints: Theme.breakPoints }}>
							<Component {...pageProps} />
						</ThemeProvider>
					</Provider>
				</DbProvider>
			</>
		)
	}
}

export default withReduxStore(Aploze)
