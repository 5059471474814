import { createContext, useContext } from 'react'
import { Message, MessagesResponse } from 'types/Message'
import useFirebaseDb from './useFirebaseDb'

const firebaseDbContext = createContext({
	//@ts-ignore

	getApiMessages: (eventId, params): Promise<MessagesResponse> => {},
	//@ts-ignore
	listenModeratedMessages: (eventId, handler: (messages: Message[]) => void) => () => {},
	//@ts-ignore
	listenPinnedMessages: (eventId, handler: (messages: Message[]) => void) => () => {},
	//@ts-ignore
	listenBannedUsers: (eventId, handler) => () => {}
})

export function DbProvider({ children }) {
	const db = useFirebaseDb()
	return <firebaseDbContext.Provider value={db}>{children}</firebaseDbContext.Provider>
}

export const useDb = () => useContext(firebaseDbContext)
