import Firebase from '@helpers/firebase'
import { FirestoreDataConverter, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { Filter, FirebaseMessage, Message, MessagesResponse } from 'types/Message'
import { OrderByDirection } from '@google-cloud/firestore'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

export default function useFirebaseDb() {
	//
	const db = Firebase.firestore()

	const getApiMessages = (eventId: string, params: Record<string, any>): Promise<MessagesResponse> => {
		return fetch(`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${eventId}/messages`, {
			method: 'PATCH',
			body: JSON.stringify(params),
			headers: {
				'Content-type': 'application/json; charset=UTF-8'
			}
		}).then((r) => r.json())
	}

	const listenMessages = (
		eventId: string,
		handler: (messages: Message[]) => void,
		filter: Filter,
		order: OrderByDirection = 'desc'
	): (() => void) => {
		const messagesDocs = db
			.collection('messages')
			.withConverter(messagesConverter)
			.where('event', '==', db.collection('events').doc(eventId))
			.where(filter.name, '==', filter.value)
			.orderBy('created_at', order)

		return messagesDocs.onSnapshot(
			(snapshot) => handler(snapshot.docs.map((doc) => doc.data()).map(fromFirebase)),
			(err) => {
				console.log(`Encountered error, ${err}`)
			}
		)
	}

	const listenModeratedMessages = (eventId: string, handler: (messages: Message[]) => void): (() => void) => {
		return listenMessages(eventId, handler, { name: 'is_moderated', value: true }, 'asc')
	}

	const listenPinnedMessages = (eventId: string, handler: (messages: Message[]) => void): (() => void) => {
		return listenMessages(eventId, handler, { name: 'is_pinned', value: true }, 'asc')
	}

	const listenBannedUsers = (eventId: string, handler: Function): (() => void) => {
		const bannedUsersDoc = db.collection('blacklist').where('event', '==', db.collection('events').doc(eventId))
		return bannedUsersDoc.onSnapshot(
			(snapshot) => handler(snapshot),
			(err) => {
				console.log(`Encountered error, ${err}`)
			}
		)
	}

	return {
		getApiMessages,
		listenModeratedMessages,
		listenPinnedMessages,
		listenBannedUsers
	}
}

const fromFirebase = (data: FirebaseMessage): Message => {
	return {
		value: data.value,
		created_at: data.created_at
			? data.created_at.toDate().toString()
			: new Date(data.created_at.toMillis()).toString(),
		eventId: data.event.id,
		is_brand: data.is_brand,
		is_moderated: data.is_moderated,
		is_pinned: data.is_pinned,
		is_question: data.is_question,
		is_shown: data.is_shown,
		was_shown: data.was_shown,
		user_displayName: data.user_displayName,
		user_uid: data.user_uid,
		uuid: data.uuid,
		parent_message: data.parent_message?.id,
		parent_user_displayName: data.parent_user_displayName,
		parent_value: data.parent_value,
		parent_user_uid: data.parent_user_uid,
		parent_viewer_uid: data.parent_viewer_uid,
		viewerId: data.viewer?.id,
		is_banned: undefined
	}
}

const messagesConverter: FirestoreDataConverter<FirebaseMessage> = {
	toFirestore: (message: FirebaseMessage) => {
		return { ...message }
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot): FirebaseMessage => {
		const data = snapshot.data()

		return {
			value: data.value,
			created_at: data.created_at || null,
			event: data.event,
			is_brand: data.is_brand,
			is_moderated: data.is_moderated,
			is_pinned: data.is_pinned,
			is_question: data.is_question,
			is_shown: data.is_shown,
			was_shown: data.was_shown || undefined,
			user_displayName: data.user_displayName || null,
			user_uid: data.user_uid,
			uuid: data.uuid,
			parent_message: data.parent_message || null,
			parent_user_displayName: data.parent_user_displayName || null,
			parent_value: data.parent_value || null,
			parent_user_uid: data.parent_user_uid || null,
			parent_viewer_uid: data.parent_viewer_uid,
			viewer: data.viewer
		}
	}
}
